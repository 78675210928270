<template>
  <div>
    <div v-if="priority=== 'LOW'" class="p-t p-blue" v-text="'Baja'" />
    <div v-if="priority=== 'NORMAL'" class="p-t p-yellow" v-text="'Media'" />
    <div v-if="priority=== 'HIGH'" class="p-t p-red" v-text="'Alta'" />
  </div>
</template>

<script>
export default {
  name: 'QuizPriority',
  props: {
    priority: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.p-t{
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.p-blue {
  color: #4E5B8C;
}
.p-yellow {
  color: #C7CA4D;
}
.p-red {
  color: #DD4444;
}
</style>
