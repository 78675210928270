<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <!-- Other header -->
      <div class="d-flex justify-space-between">
        <h3 class="subheader__title mb-2">
          Placement Quiz
        </h3>
      </div>
      <!-- Tab -->
      <v-tabs v-model="tab" class="mb-5" color="secondary">
        <v-tab class="text-none">
          Respondents
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <home-table />
      </v-tabs-items>
    </v-main>
  </v-container>
</template>

<script>
import HomeTable from '@/components/home/HomeTable'

export default {
  name: 'Home',
  components: {
    HomeTable
  },
  data () {
    return {
      tab: null
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  &__header {
    height: 120px;
  }

  &__drawer {
    background-color: #0091CD;
  }
}

.subheader {
  &__title {
    font-size: 30px;
    color: #243674;
  }

  &__input-link {
    width: 220px;
  }
}
</style>
