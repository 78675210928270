<template>
  <div class="detail mx-3">
    <div class="detail__header px-2 d-flex justify-space-between align-center">
      <div class="ml-3">
        <!-- <v-btn icon color="white" disabled>
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn> -->
        Respondent {{ quiz.n }}
        <!-- <v-btn icon color="white">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn> -->
      </div>
      <v-btn icon color="white" @click="closeDetail">
        <v-icon>
          mdi-close-circle-outline
        </v-icon>
      </v-btn>
    </div>
    <div class="detail__container my-2">
      <!-- Title -->
      <div class="py-3 px-5">
        <span class="detail__title">Interest</span>
        <v-chip label text-color="white" small color="#C6C6C6" class="ml-3">
          {{ quiz.interest || '-' }}
        </v-chip>
        <span class="detail__title ml-10">Device</span>
        <v-chip label text-color="white" small color="#C6C6C6" class="ml-3">
          {{ quiz.device }}
        </v-chip>
      </div>
      <!-- Card -->
      <div v-if="loaderAnswers" class="d-flex justify-center mt-15 mb-10">
        <v-progress-circular indeterminate color="primary" :size="50" />
      </div>
      <div v-else>
        <div v-for="(answer, i) in answers" :key="i">
          <div class="mx-5 my-5 d-flex">
            <answer-icon :answer="answer" />
            <div class="ml-3">
              <span class="detail__title" v-text="answer.question" />
              <answer :answer="answer" />
            </div>
          </div>
          <v-divider />
        </div>

        <div class="ma-5">
          <span class="detail__title">Nivel de usuario</span>
          <v-select v-model="levelSelected" :loading="loading" placeholder="Seleccionar un nivel" :items="levels"
                    item-value="value"
                    item-text="name" @change="userLevelChanged($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnswerIcon from '@/components/answer/AnswerIcon'
import Answer from '@/components/answer/Answer'
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import { sendMailAfterAssignLevelToQuiz } from '@/services/quizService'

export default {
  name: 'DetailTable',
  components: {
    AnswerIcon,
    Answer
  },
  props: {
    quiz: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      levels: [
        { name: 'BEGINNERS TWO', value: 'BEGINNERS_TWO' },
        { name: 'BEGINNERS THREE', value: 'BEGINNERS_THREE' },
        { name: 'INTERMEDIATE', value: 'INTERMEDIATE' },
        { name: 'CONVERSACIONAL', value: 'CONVERSACIONAL' }
      ],
      levelSelected: null,
      loading: false
    }
  },
  computed: {
    ...mapState('quiz', ['answers', 'loaderAnswers'])
  },
  watch: {
    quiz () {
      this.levelSelected = this.quiz.userLevel
    }
  },
  mounted () {
    if (this.quiz) {
      this.levelSelected = this.quiz.userLevel
    }
  },
  methods: {
    ...mapActions('quiz', ['updateQuizInformation']),
    closeDetail () {
      this.$emit('closeDetail')
    },
    async userLevelChanged ($event) {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        icon: 'info',
        html: 'Recuerda que se le enviará un correo al usuario después de esta acción.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          this.levelSelected = $event
          // Update level user in firestore
          await this.updateQuizInformation({ quizId: this.quiz.id, payload: { userLevel: this.levelSelected } })

          // Send email
          const data = {
            fullName: this.quiz.name,
            email: this.quiz.email,
            userLevel: this.levelSelected,
            phone: this.quiz.phone
          }
          await sendMailAfterAssignLevelToQuiz(data)
          this.loading = false
          await Swal.fire({
            icon: 'success',
            text: 'Se ha enviado el email correctamente.'
          })
        } catch (e) {
          await Swal.fire({
            icon: 'error',
            title: 'Ups...',
            text: 'Algo salió mal. Inténtalo de nuevo en unos minutos!'
          })
        }
      } else {
        setTimeout(() => {
          this.levelSelected = this.quiz.userLevel
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  width: 600px;
  height: 47px;
  background-color: #0091CD;

  &__header {
    height: 100%;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }

  &__container {
    border: 1px solid #CFCFCF;
    max-height: 63vh;
    overflow: auto;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #243674;
  }
}

li {
  color: #67757C;
}
</style>
