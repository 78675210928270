<template>
  <v-btn :color="color" dark>
    <v-icon color="white">
      {{ icon }}
    </v-icon>
    {{ answer.questionIndex }}
  </v-btn>
</template>

<script>
export default {
  name: 'AnswerIcon',
  props: {
    answer: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      icon: '',
      color: ''
    }
  },
  created () {
    if (this.answer.answerType === 'AUDIO') {
      this.icon = 'mdi-volume-high'
      this.color = '#DD4444'
    }
    if (this.answer.answerType === 'TEXT') {
      this.icon = 'mdi-pencil'
      this.color = '#0091CA'
    }
    if (this.answer.answerType === 'ALTERNATIVE') {
      this.icon = 'mdi-hand-pointing-up'
      this.color = '#243674'
    }
  }
}
</script>
