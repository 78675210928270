<template>
  <base-modal v-bind="$attrs" width="660px" v-on="$listeners" @click:outside="close" @close="close">
    <template v-slot:title>
      Asignar a un curso
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <v-col class="py-0" cols="12">
            <base-select v-model="student.course" :items="allClasses" :rules="[rules.required]" return-object item-value="id">
              <template v-slot:selection="data">
                {{ data.item.level.name }} - {{ data.item.day }} - {{ data.item.startHour }} a {{ data.item.endHour }}
              </template>
              <template v-slot:item="data">
                {{ data.item.level.name }} - {{ data.item.day }} - {{ data.item.startHour }} a {{ data.item.endHour }}
              </template>
            </base-select>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-checkbox
              v-model="student.guest" label="Alumno invitado" color="#243674" hide-details
              height="50px"
              plain outlined
              class="mt-0"
              disabled
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:actions>
      <form-button elevation="0" width="307px" height="50px" class="mt-5 mb-2 mx-auto" :loading="loading" @click="save">
        Guardar
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import formButton from '@/components/buttons/FormButton'
import BaseSelect from '@/components/inputs/BaseSelect'
import { mapActions, mapState } from 'vuex'
import { required } from '@/utils/rules'
import momentFunctions from '@/utils/momentFunctions'

export default {
  name: 'AssignClassModal',
  components: {
    BaseSelect,
    baseModal,
    formButton
  },
  inheritAttrs: false,
  data () {
    return {
      student: {
        course: '',
        guest: true
      },
      loading: false,
      rules: {
        required
      },
      momentFunctions
    }
  },
  computed: {
    ...mapState('schedule', ['allClasses']),
    ...mapState('quiz', ['quizToAssign'])
  },
  async mounted () {
    await this.getAllClasses()
  },
  methods: {
    ...mapActions('schedule', ['getAllClasses', 'editClass']),
    ...mapActions('quiz', ['editQuiz']),
    cleanFields () {
      this.student = {
        course: '',
        guest: true
      }
    },
    close () {
      this.cleanFields()
      this.$emit('close')
    },
    async save () {
      if (this.$refs.form.validate()) {
        try {
          if (this.student.course.studentsIds.includes(this.student.email)) {
            this.$root.$confirm({
              message: 'Este estudiante ya ha sido asignado a esta clase.',
              type: 'alert'
            })
          }
          this.loading = true
          const studentPayload = {
            firstName: this.quizToAssign.name.split(' ')[0],
            lastName: this.quizToAssign.name.split(' ')[1] || '',
            email: this.quizToAssign.email,
            guest: this.student.guest
          }
          await this.assignStudentInClass(studentPayload, this.student.course)
          await this.editQuiz({ quizId: this.quizToAssign.id, data: { studentId: studentPayload.email } })
          this.$root.$confirm({
            message: 'Se ha guardado correctamente la información'
          })
          this.loading = false
          this.close()
        } catch (e) {
          console.log(e)
          this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            type: 'alert'
          })
        }
      }
    },
    async assignStudentInClass (student, classObject) {
      const newStudentsObject = { ...classObject.students }
      newStudentsObject[student.email] = {
        ...student,
        createdAt: new Date(),
        active: true
      }
      await this.editClass({
        classId: classObject.id,
        updatedClass: {
          students: newStudentsObject,
          studentsIds: Object.keys(newStudentsObject)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
