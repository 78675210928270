<template>
  <div>
    <div class="d-flex">
      <v-simple-table style="width: 100%;" fixed-header height="70vh">
        <thead class="table__head">
          <tr>
            <th v-for="(th, i) in tableHead" :key="i" class="text-left" v-text="th" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="quiz in listQuizzesFiltered" :key="quiz.code" class="table__tr"
              :class="{ 'quiz-answered' :quiz.userLevel, 'quiz-select' :quiz === quizSelect, 'quiz-assigned' :quiz.studentId}"
              @click="selectQuiz(quiz)"
          >
            <td v-text="quiz.code" />
            <td>{{ quiz.date.seconds | moment('MMMM Do, YYYY') }}</td>
            <td v-text="quiz.name" />
            <td v-text="quiz.email" />
            <td v-text="quiz.city || '-'" />
            <td v-text="quiz.phone" />
            <td>
              <quiz-priority :priority="quiz.priority" />
            </td>
            <td>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="dropQuiz(quiz)">
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="assignCourse(quiz)">
                    <v-list-item-title>Asignar curso</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <infinite-loading @infinite="infiniteHandler" />
        </tbody>
      </v-simple-table>
      <detail-table v-if="showDetail" :quiz="quizSelect" @closeDetail="showDetail=false" />
      <assign-class-modal v-model="showAssignCourseModal" @close="closeAssignClassModal()"
                          @close:outside="closeAssignClassModal"
      />
    </div>
  </div>
</template>

<script>
import DetailTable from '@/components/home/DetailTable'
import QuizPriority from '@/components/QuizPriority'
import { mapState, mapActions } from 'vuex'
import AssignClassModal from '@/components/modals/AssignClassModal'

export default {
  name: 'HomeTable',
  components: {
    DetailTable,
    QuizPriority,
    AssignClassModal
  },
  data () {
    return {
      tableHead: ['#', 'Date', 'Name', 'Email', 'Ciudad', 'Teléfono', 'Importancia', ''],
      showDetail: false,
      quizSelect: {},
      loading: true,
      showAssignCourseModal: false
    }
  },
  computed: {
    ...mapState('quiz', ['quizzes', 'existNextQuizzes', 'searchSurveys', 'quizToAssign']),
    listQuizzesFiltered () {
      return this.quizzes.filter(quiz => {
        return quiz.email.toLowerCase().includes(this.searchSurveys.toLowerCase()) ||
          quiz.name.toLowerCase().includes(this.searchSurveys.toLowerCase())
      })
    }
  },
  watch: {
    quizToAssign (newValue) {
      if (newValue) {
        this.showAssignCourseModal = true
      }
    }
  },
  methods: {
    ...mapActions('quiz', ['getQuizzes', 'getAnswers', 'deleteQuiz', 'setQuizToAssign']),
    async infiniteHandler ($state) {
      await this.getQuizzes()
      this.existNextQuizzes ? $state.loaded() : $state.complete()
    },
    async selectQuiz (quiz) {
      this.showDetail = true
      this.quizSelect = quiz
      await this.getAnswers(quiz.id)
    },
    async dropQuiz (quiz) {
      try {
        const confirm = window.confirm('Se eliminará el registro de forma permanente.')
        if (confirm) await this.deleteQuiz(quiz)
      } catch (e) {
        console.log(e)
      }
    },
    assignCourse (item) {
      if (item.studentId) {
        this.$root.$confirm({ message: 'Este quiz ya tiene un curso asignado.', type: 'alert' })
      } else {
        this.setQuizToAssign(item)
      }
    },
    closeAssignClassModal () {
      this.showAssignCourseModal = false
      this.setQuizToAssign(null)
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  color: white !important;
  background-color: #0091CD !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

td {
  font-weight: normal;
  color: #67757C !important;
  font-size: 16px !important;
}

tr {
  cursor: pointer;
}

tr:hover td {
  background-color: #80c8e6;
}

.quiz-select td {
  background-color: #80c8e6;
}

.quiz-answered td {
  background-color: lightgrey;
}

.quiz-assigned td {
  background-color: rgb(198, 202, 214);
}
</style>
