<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <div class="d-flex justify-space-between align-center">
        <h3 class="subheader__title mb-8">
          Lista de usuarios
        </h3>

        <base-text-field v-model="search" label="Buscar" @keyup="searchStudents" />
      </div>
      <div class="d-flex flex-column">
        <students-table />
      </div>
    </v-main>
  </v-container>
</template>

<script>
import studentsTable from '@/components/students/StudentsTable'
import { mapActions, mapState } from 'vuex'
import baseTextField from '@/components/inputs/BaseTextField'

export default {
  name: 'Index',
  components: {
    studentsTable,
    baseTextField
  },
  data () {
    return {
      timeout: null,
      search: ''
    }
  },
  computed: {
    ...mapState('students', ['sortBy', 'orientation', 'requestAllStudents'])
  },
  methods: {
    ...mapActions('students', ['setSearchValue', 'getAllStudents', 'setRequestAllStudents']),
    searchStudents () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (this.requestAllStudents) {
          await this.getAllStudents({ sortBy: this.sortBy, orientation: this.orientation })
          await this.setRequestAllStudents(false)
        }
        await this.setSearchValue(this.search)
      }, 600)
    }
  }
}
</script>

<style scoped>

</style>
