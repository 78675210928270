<template>
  <div>
    <div v-if="answer.answerType === 'ALTERNATIVE'">
      <ul>
        <li class="mt-3 answer">
          {{ answer.answer }}
        </li>
      </ul>
    </div>
    <div v-if="answer.answerType === 'TEXT'">
      <p class="mt-3 answer">
        {{ answer.answer }}
      </p>
    </div>
    <div v-if="answer.answerType === 'AUDIO'">
      <answer-player :audio-url="linkAudio" />
    </div>
  </div>
</template>

<script>
import AnswerPlayer from '@/components/answer/AnswerPlayer'
import { storage } from '@/plugins/firebase'

export default {
  name: 'Answer',
  components: {
    AnswerPlayer
  },
  props: {
    answer: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      linkAudio: ''
    }
  },
  async created () {
    if (this.answer.answerType === 'AUDIO') {
      try {
        const linkAudio = await storage.ref(this.answer.answer).getDownloadURL()
        this.linkAudio = linkAudio.toString()
      } catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.answer {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #67757C;
}
</style>
