<template>
  <div class="students-container d-flex flex-column">
    <tslc-table :heads="heads" :items="filteredStudents" @sort="sort">
      <template v-slot:nombres="{item}">
        {{ item.firstName }} {{ item.lastName }}
      </template>
      <template v-slot:createdAt="{item}">
        {{ getFormattedDate(item.createdAt) }}
      </template>
      <template v-slot:verified="{item}">
        <div class="text-center">
          <v-icon :color="item.verified? 'success': 'red lighten-2'">
            {{ item.verified? 'mdi-check-circle': 'mdi-close-circle' }}
          </v-icon>
        </div>
      </template>
      <template v-slot:infinite>
        <td :colspan="heads.length">
          <infinite-loading ref="studentsInfiniteLoading" @infinite="infiniteHandler">
            <template v-slot:no-more>
              <div />
            </template>
            <template v-slot:no-results>
              <div />
            </template>
          </infinite-loading>
        </td>
      </template>
    </tslc-table>
  </div>
</template>

<script>
import tslcTable from '@/components/TslcTable'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StudentsTable',
  components: {
    tslcTable
  },
  data () {
    return {
      heads: [
        {
          name: 'Nombres',
          value: 'nombres',
          sortBy: 'firstName'
        },
        {
          name: 'Correo electrónico',
          value: 'email',
          sortBy: 'email'
        },
        {
          name: 'Creación',
          value: 'createdAt',
          sortBy: 'createdAt'
        },
        {
          name: 'Verificado',
          value: 'verified',
          sortBy: 'verified'
        },
        {
          name: '',
          value: 'actions'
        }
      ],
      studentsListComplete: false
    }
  },
  computed: {
    ...mapState('students', ['students', 'existNextStudents', 'searchValue', 'sortBy', 'orientation', 'requestAllStudents']),
    filteredStudents () {
      const filteredList = this.students.filter(student => {
        if (student.email && student.email.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return student
        }
        if (student.firstName && student.firstName.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return student
        }
      })
      if (this.requestAllStudents) {
        return filteredList
      }

      return filteredList.sort((a, b) => {
        const sortOrder = this.orientation === 'desc' ? -1 : 1
        if (this.sortBy === 'firstName') {
          const aName = a.firstName ? a.firstName.toLowerCase() : a.lastName ? a.lastName.toLowerCase() : 'zz'
          const bName = b.firstName ? b.firstName.toLowerCase() : b.lastName ? b.lastName.toLowerCase() : 'zz'
          const result = aName < bName ? -1 : aName > bName ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'email') {
          const aName = a.email || 'zz'
          const bName = b.email || 'zz'
          const result = aName < bName ? -1 : aName > bName ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'createdAt') {
          const aStartDate = a.createdAt ? this.$moment(a.createdAt.toDate()) : this.$moment('2001-01-01')
          const bStartDate = b.createdAt ? this.$moment(b.createdAt.toDate()) : this.$moment('2001-01-01')
          const result = aStartDate < bStartDate ? -1 : aStartDate > bStartDate ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'verified') {
          const result = a.verified === b.verified ? 0 : a.verified ? -1 : 1
          return result * sortOrder * -1
        }
        return filteredList
      })
    }
  },
  mounted () {
    this.resetInfiniteLoading()
  },
  methods: {
    ...mapActions('students', ['getStudents', 'resetStudents', 'setSortBy', 'setOrientation', 'setRequestAllStudents']),
    async infiniteHandler ($state) {
      if (!this.requestAllStudents) {
        $state.complete()
        return
      }
      await this.getStudents({ sortBy: this.sortBy, orientation: this.orientation })
      this.existNextStudents ? $state.loaded() : $state.complete()
    },
    async resetInfiniteLoading () {
      await this.resetStudents()
      await this.$refs.studentsInfiniteLoading.stateChanger.reset()
    },
    async sort ({ sortBy, orientation }) {
      await this.setSortBy(sortBy)
      await this.setOrientation(orientation)
      if (this.requestAllStudents) {
        await this.resetInfiniteLoading()
      }
    },
    getFormattedDate (date) {
      if (!date) {
        return '-'
      }
      return this.$moment(date.toDate()).format('MMMM Do, YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.students-container ::v-deep .v-data-table__wrapper {
  height: 70vh;
}

</style>
