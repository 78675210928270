import { render, staticRenderFns } from "./AssignClassModal.vue?vue&type=template&id=e4d1788e&scoped=true"
import script from "./AssignClassModal.vue?vue&type=script&lang=js"
export * from "./AssignClassModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4d1788e",
  null
  
)

export default component.exports